'use client'
import React, { useState, useEffect } from 'react'
import { FaUser } from 'react-icons/fa'
import { MdOutlinePhoneIphone, MdEmail } from 'react-icons/md'
import { AiFillWechat } from 'react-icons/ai'
import { FaLine } from 'react-icons/fa'
import { sendSellForm } from '@services/sendSellForm'
import { useSession } from 'next-auth/react'
import tailwindConfig from '@tailwind.config'
import { Combobox, Listbox } from "@headlessui/react"
import { countryCodes } from '@constants/countryCodes'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { BiSolidBuildingHouse } from "react-icons/bi"
import { sellSaleTypes } from '@constants';
import { preferredContact } from '@constants/preferredContact';
import { RadioGroup } from '@headlessui/react'
import Image from 'next/image'
import { FaCheckCircle } from "react-icons/fa";


const SellForm = ({ dictionary, lang }) => {
    const { data: session } = useSession()

    let [sale, setSale] = useState(sellSaleTypes[lang][0].value)
    // let [addChecked, setAddChecked] = useState(saleTypes[0].value)
    const [type, setType] = useState('')
    const [contact, setContact] = useState(preferredContact[lang][0])


    const [userId, setUserId] = useState(null)
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [line, setLine] = useState('')
    const [wechat, setWechat] = useState('')
    const [message, setMessage] = useState(``)
    const [hasEnquiry, setHasEnquiry] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(countryCodes[0].code)
    const [query, setQuery] = useState('')

    const filteredCountries =
        query === ''
            ? countryCodes
            : countryCodes.filter((country) => {
                return country.country.toLowerCase().includes(query.toLowerCase()) || country.code.toLowerCase().includes(query.toLowerCase())
            })

    useEffect(() => {
        const setUserData = () => {
            if (session?.user) {
                // console.log(session?.user)
                setUserId(session?.user.id)
                setName(session?.user.name)
                setPhone(session?.user.phone)
                setSelectedCountry(session?.user.phonePrefix || countryCodes[0].code)
                setEmail(session?.user.email)
            }
        }
        setUserData()
    }, [session])

    const handleForm = async (e) => {
        e.preventDefault()
        await sendSellForm(userId, name, selectedCountry, phone, email, message, sale, type, contact, line, wechat)
        setHasEnquiry(true)
    }

    const selectTextOnClick = (e) => {
        e.target.select();
    };

    return (
        <div className='2xl:w-8/12 mx-auto'>
            {!hasEnquiry ? (
                <div className='xl:mb-0 gap-4 pb-3 xl:border-b-0 border-b-[1px] border-b-forth flex flex-col sm:flex-row'>
                    <div className='w-full sm:w-[600px] relative flex-col overflow-hidden border-[1px] border-grey-300 shadow-sm rounded-md'>
                        <div className='absolute  top-0 bottom-0 left-0 right-0 overflow-hidden w-full h-full'>
                            <Image 
                                src={'/images/listwithus2.jpg'}
                                width={1252}
                                height={646}
                                // className='object-none'
                                className="absolute inset-0 w-full h-full object-cover"
                            />
                        </div>
                        <div className='relative'>
                            <div className='h-full flex flex-col p-2 sm:p-4'>
                                <h2 className='text-center text-2xl font-bold text-maincolor mb-4'>Steps to list your property with RE/MAX</h2>
                                <div className='flex flex-col gap-2 sm:gap-4'>
                                    <div className='relative rounded-full bg-white border border-secondary'>
                                    <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>1</div>
                                    <div className='flex items-row items-center overflow-hidden'>
                                        <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                        <h3 className='font-bold text-md sm:text-xl'>Consultation</h3>
                                        </div>
                                        <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>Meet with a RE/MAX Agent to discuss your property and goals</p></div>
                                    </div>
                                    </div>
                                    <div className='relative rounded-full bg-white border border-secondary'>
                                    <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>2</div>
                                    <div className='flex items-row items-center overflow-hidden'>
                                        <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                        <h3 className='font-bold  text-md sm:text-xl'>Evaluation</h3>
                                        </div>
                                        <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>The Agent assesses your property and sets the selling price</p></div>
                                    </div>
                                    </div>
                                    <div className='relative rounded-full bg-white border border-secondary'>
                                    <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>3</div>
                                    <div className='flex items-row items-center overflow-hidden'>
                                        <div className='w-1/3 flex px-4 sm:px-8 items-center justify-start'>
                                        <h3 className='font-bold  text-md sm:text-xl'>Listing Agreement</h3>
                                        </div>
                                        <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>Sign the agreement to authorize marketing and sales</p></div>
                                    </div>
                                    </div>
                                    <div className='relative rounded-full bg-white border border-secondary'>
                                    <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>4</div>
                                    <div className='flex items-row items-center overflow-hidden'>
                                        <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                        <h3 className='font-bold  text-md sm:text-xl'>Preparation</h3>
                                        </div>
                                        <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>Prepare the property with cleaning, staging and photos</p></div>
                                    </div>
                                    </div>
                                    <div className='relative rounded-full bg-white border border-secondary'>
                                    <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>5</div>
                                    <div className='flex items-row items-center overflow-hidden'>
                                        <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                        <h3 className='font-bold text-md sm:text-xl'>Marketing</h3>
                                        </div>
                                        <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>A marketing plan is created, including online listings and social media</p></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-1/2 w-full border-none px-3 sm:px-0 sm:border-l-[1px]'>
                        <h2 className='text-maincolor font-semibold text-xl mb-1'>{dictionary.fillTheForm}</h2>
                        <form onSubmit={handleForm}>
                            <label className='text-secondary text-sm'>{dictionary.want}</label>
                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left text-secondary sm:text-sm'>
                                <RadioGroup value={sale} onChange={(value) => {
                                    setSale(value)
                                }}
                                    className='w-full py-1 mt-1 flex flex-row'
                                >
                                    {sellSaleTypes[lang].map((saleType) => (
                                        <RadioGroup.Option
                                            key={saleType.value}
                                            value={saleType.value}
                                            checked={saleType.value === sale}
                                            className={({ active, checked }) =>
                                                `${active
                                                    ? ''
                                                    : ''
                                                }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                                }
                                    w-full relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
                                            }
                                        >
                                            {({ active, checked }) => (
                                                <>
                                                    <div className="flex w-full items-center justify-between">
                                                        <div className="flex items-center">
                                                            <div className="text-sm text-center">
                                                                <RadioGroup.Label
                                                                    as="p"
                                                                    className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                        }`}
                                                                >
                                                                    {saleType.title}
                                                                </RadioGroup.Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    ))}
                                </RadioGroup>
                            </div>
                            <label className='text-secondary text-sm'>{dictionary.typeOfProperty}</label>
                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                <BiSolidBuildingHouse
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                    size={28}
                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                />
                                <input
                                    className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                    placeholder={dictionary.salePlaceholder}
                                    type='text'
                                    name='type'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    required
                                />
                            </div>
                            <label className='text-secondary text-sm'>{dictionary.propertyDescription}</label>
                            <div className='w-full py-1'>
                                <textarea
                                    className='w-full border-[1px] border-forth text-secondary rounded-md p-2'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    rows={3}
                                    required
                                    placeholder={dictionary.descriptionPlaceholder}
                                />
                            </div>
                            <label className='text-secondary text-sm'>{dictionary.name}</label>
                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                <FaUser
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                    size={28}
                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                />
                                <input
                                    className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                    placeholder={dictionary.namePlaceholder}
                                    type='text'
                                    name='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <label className='text-secondary text-sm'>{dictionary.preferredContact}</label>
                            <Listbox value={contact} onChange={setContact}>
                                <div className='z-1 relative w-full cursor-default rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:shadow-md focus:outline-none  sm:text-sm">
                                        <span className="block truncate">{contact.name}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <RiArrowDropDownLine
                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                size={28}
                                            />
                                        </span>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                                        {preferredContact[lang].map((cont, contIdx) => (
                                            <Listbox.Option
                                                key={contIdx}
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-maincolor' : 'text-secondary'
                                                    }`
                                                }
                                                value={cont}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                }`}
                                                        >
                                                            {cont.name}
                                                        </span>
                                                        {selected ? (
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                            {(contact.value == 'phone' || contact.value == 'whatsapp' || contact.value == 'viber') && (
                                <>
                                    <label className='text-secondary text-sm'>{dictionary.phone}</label>
                                    <div className='flex flex-row w-full py-1'>
                                        {/* <CountryCode className="w-1/4" /> */}
                                        <Combobox className="w-1/4"
                                            value={selectedCountry}
                                            onChange={setSelectedCountry}
                                        >
                                            <div className="relative w-full">
                                                <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                                    <Combobox.Input onClick={selectTextOnClick} onChange={(event) => setQuery(event.target.value)} required className='w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0' />
                                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <RiArrowDropDownLine
                                                            color={tailwindConfig.theme.extend.colors.secondary}
                                                            size={28}
                                                        />
                                                    </Combobox.Button>
                                                </div>
                                                <Combobox.Options
                                                    as="ul" // Render the Combobox.Options as an unordered list (ul) to contain the options
                                                    className='z-50 left-0 right-0 absolute bg-white h-auto w-[300px] max-h-[200px] overflow-y-auto shadow-md rounded-md py-1'
                                                >
                                                    {filteredCountries.map((country) => (
                                                        <Combobox.Option
                                                            key={country.id}
                                                            value={country.code}
                                                            className='py-2 px-4 cursor-pointer color-secondary z-50'
                                                        >
                                                            {country.code} {country.country}
                                                        </Combobox.Option>
                                                    ))}
                                                </Combobox.Options>
                                            </div>
                                        </Combobox>
                                        <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                            <MdOutlinePhoneIphone
                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                size={30}
                                                className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                            />
                                            <input
                                                className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                placeholder={dictionary.phonePlaceholder}
                                                required
                                                type='text'
                                                name='phone'
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(contact.value == 'line') && (
                                <>
                                    <label className='text-secondary text-sm'>{dictionary.line}</label>
                                    <div className='w-full py-1'>
                                        <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                            <FaLine
                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                size={30}
                                                className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                            />
                                            <input
                                                className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                placeholder={dictionary.line}
                                                type='line'
                                                name='line'
                                                value={line}
                                                onChange={(e) => setLine(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(contact.value == 'wechat') && (
                                <>
                                    <label className='text-secondary text-sm'>{dictionary.wechat}</label>
                                    <div className='w-full py-1'>
                                        <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                            <AiFillWechat
                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                size={30}
                                                className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                            />
                                            <input
                                                className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                                placeholder={dictionary.wechatPlaceholder}
                                                type='wechat'
                                                name='wechat'
                                                value={wechat}
                                                onChange={(e) => setWechat(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <label className='text-secondary text-sm'>{dictionary.email}</label>
                            <div className='w-full py-1'>
                                <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                                    <MdEmail
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                        size={30}
                                        className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                    />
                                    <input
                                        className='w-full border-none py-2 pl-10 text-sm leading-5 text-gray-900 focus:ring-0'
                                        placeholder={dictionary.emailPlaceholder}
                                        type='email'
                                        name='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <button className='w-full p-2 bg-third text-light font-bold rounded-md' type='submit'>{dictionary.send}</button>
                        </form>
                    </div>

                </div>) : (
                <div className='xl:mb-0 pb-3 xl:border-b-0 border-b-[1px] border-b-forth flex flex-col sm:flex-row'>
                    <div className='w-full sm:w-[600px] relative flex-col overflow-hidden border-[1px] border-grey-300 shadow-sm rounded-md'>
                        <div className='absolute  top-0 bottom-0 left-0 right-0 overflow-hidden w-full h-full'>
                            <Image 
                                src={'/images/listwithus2.jpg'}
                                width={1252}
                                height={646}
                                // className='object-none'
                                className="absolute inset-0 w-full h-full object-cover"
                            />
                        </div>
                        <div className='relative'>
                            <div className='h-full flex flex-col p-2 sm:p-4'>
                            <h2 className='text-center text-2xl font-bold text-maincolor mb-4'>Steps to list your property with RE/MAX</h2>
                            <div className='flex flex-col gap-2'>
                                <div className='relative rounded-full bg-white border border-secondary'>
                                <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>1</div>
                                <div className='flex items-row items-center overflow-hidden'>
                                    <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                    <h3 className='font-bold text-md sm:text-xl'>Consultation</h3>
                                    </div>
                                    <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>Meet with a RE/MAX Agent to discuss your property and goals</p></div>
                                </div>
                                </div>
                                <div className='relative rounded-full bg-white border border-secondary'>
                                <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>2</div>
                                <div className='flex items-row items-center overflow-hidden'>
                                    <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                    <h3 className='font-bold  text-md sm:text-xl'>Evaluation</h3>
                                    </div>
                                    <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>The Agent assesses your property and sets the selling price</p></div>
                                </div>
                                </div>
                                <div className='relative rounded-full bg-white border border-secondary'>
                                <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>3</div>
                                <div className='flex items-row items-center overflow-hidden'>
                                    <div className='w-1/3 flex px-4 sm:px-8 items-center justify-start'>
                                    <h3 className='font-bold  text-md sm:text-xl'>Listing Agreement</h3>
                                    </div>
                                    <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>Sign the agreement to authorize marketing and sales</p></div>
                                </div>
                                </div>
                                <div className='relative rounded-full bg-white border border-secondary'>
                                <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>4</div>
                                <div className='flex items-row items-center overflow-hidden'>
                                    <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                    <h3 className='font-bold  text-md sm:text-xl'>Preparation</h3>
                                    </div>
                                    <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>Prepare the property with cleaning, staging and photos</p></div>
                                </div>
                                </div>
                                <div className='relative rounded-full bg-white border border-secondary'>
                                <div className='absolute -top-1 -left-1 flex items-center justify-center text-white text-xl font-bold rounded-full bg-third h-6 sm:h-8 w-6 sm:w-8'>5</div>
                                <div className='flex items-row items-center overflow-hidden'>
                                    <div className='w-1/3 flex flex-grow-0 px-4 sm:px-8 items-center justify-start h-full'>
                                    <h3 className='font-bold text-md sm:text-xl'>Marketing</h3>
                                    </div>
                                    <div className='w-2/3 text-sm sm:text-md p-4 flex items-center rounded-full bg-maincolor text-light'><p>A marketing plan is created, including online listings and social media</p></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-1/2 w-full flex flex-col items-center justify-center border-l-[1px] py-3'>
                        <h2 className='text-maincolor font-bold text-xl'>{dictionary.enquirySent}</h2>
                        <p className='text-secondary'>{dictionary.enquirySentSubtitle}</p>
                        <FaCheckCircle
                            color={tailwindConfig.theme.extend.colors.secondary}
                            size={48}
                            className='mt-6'
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SellForm